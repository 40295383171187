<template>
  <div class="goto-node">
    <create-or-select
      :items="extractGotoNodeOptions"
      :current_select="currentSelection"
      :allow-create="allowCreate"
      :is-disabled="!isTargetEditable"
      :is-grouped="true"
      new-item-message="new node"
      :fail-to-create-message="gotoNode.error"
      :placeholder="__('Node Name')"
      @change="handleChange"
    />
    <div v-if="isTargetEditable" class="goto-node-msg">
      {{ gotoNode.msg }}
    </div>
    <!-- Show helper message for user if the target is not editable -->
    <div v-else class="info">
      <div class="warning-exclamation" />
      <div style="margin-left: 5px; display: flex">
        <div>
          {{ __("Please change the target node link in the parent node") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import { mapGetters } from "vuex";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    conditionKeyword: {
      required: true,
      type: String
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    allowCreate: {
      required: false,
      type: Boolean,
      default: true
    },
    isTargetEditable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    const gotoCondition = {
      keyword: this.conditionKeyword,
      node_name: "",
      node_id: -1,
      msg: "",
      error: "",
      keyword_error: ""
    };
    return {
      $gotoCondition: gotoCondition,
      $gotoOptions: []
    };
  },
  computed: {
    ...mapGetters("canvas", {
      canvasOfNode: "canvasOfNode"
    }),
    extractGotoNodeOptions() {
      let options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id,
        group_name: this.canvasOfNode(child.node_id) || ""
      }));
      // alphabetized FIRST by Canvas and THEN by Node
      options = _.sortBy(options, ["group_name", "label"], ["desc"]);
      return this.$data.$gotoOptions.concat(options);
    },

    gotoNode() {
      return this.$data.$gotoCondition;
    },

    currentSelection() {
      const { node_id, node_name } = this.gotoNode;
      return node_id === -1 ? node_name : node_id;
    }
  },
  methods: {
    initializeGotoNode(gotoCondition) {
      if (!_.isEmpty(gotoCondition)) {
        this.$data.$gotoCondition = _.cloneDeep(gotoCondition);
      }
    },

    handleChange(option) {
      this.$set(this.$data.$gotoCondition, "node_id", option.value);
      this.$set(this.$data.$gotoCondition, "node_name", option.label);
      this.$set(this.$data.$gotoCondition, "msg", option.msg);
    }
  },

  watch: {
    gotoNode: {
      handler: function(value) {
        this.$emit("input", {
          ...value,
          node_name: value.node_name.toString().trim()
        });
      },
      deep: true,
      immediate: true
    },
    value: {
      immediate: true,
      handler: "initializeGotoNode"
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
.goto-node ::v-deep .goto-node-msg {
  font-size: 0.75rem;
  color: $--color-success;
}

.el-form-item__content:has(> .el-form-item__error) {
  .goto-node {
    ::v-deep .el-form-item__error {
      top: 140%;
    }
  }
}
.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
</style>
